.head {
  @apply my-6 font-bold text-sky-800 text-center text-3xl;
  font-family: "FFShamel", sans-serif;
}

.btn {
  @apply disabled:opacity-70 bg-[#0078d2] text-xl  text-white py-1 px-4 rounded-md shadow-md;
}
.btnDe {
  @apply disabled:opacity-70 bg-red-600 text-xl  text-white py-1 px-4 rounded-md shadow-md;
}
.btnsm {
  @apply disabled:opacity-70 bg-[#0078d2]   text-white py-1 px-4 rounded-md shadow-md;
}
.btnsmgr {
  @apply disabled:opacity-70 bg-green-700   text-white py-1 px-4 rounded-md shadow-md;
}
.form {
  @apply flex flex-wrap items-center justify-start;
  direction: rtl
}
.formItem {
  @apply min-w-56 p-2;
}
.table{
    @apply  my-3 border  shadow-md bg-white min-w-full
}

.ani{

}





.dots {
  width: 1.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 0.3em;
  height: 0.3em;
  border-radius: 50%;
  background-color: #f3f3f3;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
