.rtl {
  direction: rtl;
}

.activem {
  @apply rounded-r-full bg-white text-[#1a2c41] shadow transition-all ease-in-out duration-500 relative;
}

.activem::before {
  content: "";
  @apply absolute top-[-14px] left-0 bg-white h-[14px] w-[14px];
}
.active::before {
  content: "";
  @apply absolute top-[-14px] left-0 rounded-bl-full bg-[#1a2c41] h-[14px] w-[14px];
}
.activem::after {
  content: "";
  @apply absolute bottom-[-14px] left-0 rounded-tl-full bg-[#1a2c41] h-[14px] w-[14px];
}
.active::after {
  content: "";
  @apply absolute bottom-[-14px] left-0 bg-white h-[14px] w-[14px];
}

@font-face {
  font-family: "FFShamel";
  src: local("FFShamel"),
    url(../public/fonts/FFShamel/FFShamel.ttf) format("truetype");
}
@font-face {
  font-family: "Amiri-Regular";
  src: local("Amiri-Regular"),
    url(../public/fonts/Amiri-Regular.ttf) format("truetype");
}
.ar-fo {
  font-family: "FFShamel", sans-serif;
}
.ar-ta {
  font-family: "Amiri-Regular", sans-serif;
}
